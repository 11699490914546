import React, { FC } from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Contact } from "@components/molecules/Contact";
import { WebsiteTitle } from "@components/atoms/WebsiteTitle";
import { AllOfferCards } from "@components/molecules/AllOfferCards";

const Offer: FC = () => {
  const { t } = useTranslation();

  return (
    <BasicTemplate
      title={t("common:title-offer")}
      description={t("common:description-offer")}
    >
      <WebsiteTitle>{t("offer:title")}</WebsiteTitle>
      <section>
        <AllOfferCards />
      </section>
      <Contact />
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Offer;
