import styled from "styled-components";
import { stylesWrapper } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";

export const WebsiteTitle = styled.h1`
  ${stylesWrapper};
  margin-top: 220px;
  font-weight: 700;
  font-size: 4.8rem;
  margin-bottom: 100px;

  ${breakpoints.laptopMax`
    font-size: 3.4rem;
    margin-top: 180px;
    margin-bottom: 80px;
  `}

  ${breakpoints.phoneMax`
    font-size: 2.4rem;
    text-align: center;
    margin-top: 120px;
    line-height: 1.5;
  `}
`;
